import React from 'react';
import 'sass/index.sass';
import Router from './router';

export default function App() {
  return (
    <>
      <Router />
    </>
  );
}
